import React, { useLayoutEffect } from 'react'

import { Box, Grid } from '@material-ui/core'
import { Formik, FormikHelpers, FormikProps, Form } from 'formik'
import { history } from 'utils'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import HelpSection from 'components/login/HelpSection'
import YourAddress from 'components/myPolicy/YourAddress'
import MyCoverDetails from 'components/myPolicy/MyCoverDetails'
import ExcessTable from 'components/ui/ExcessTable'
import PolicyHolderTable from 'components/ui/PolicyHolderTable'
import MyDrivers from 'components/myPolicy/MyDrivers'
import MyRenewalPreferences from 'components/account/MyRenewalPreferences'

import { portalTools } from 'guidewire/portalTools'
import { isEmpty } from 'guidewire/formatTools'

import { DriverDisplay, SelectedPolicy } from 'types/policy'
import { ContentType, LabelValuePair } from 'types/contentTypes'
import { EndPoint } from 'types/endpoint'
import { PolicySelectRequest } from 'types/requests'
import AlertMessage from 'components/ui/Alert/AlertMessage'
import AlertMessageInfo from 'components/ui/Alert/AlertMessageInfo'
import MarketingPreferencesSection from 'components/account/MarketingPreferencesSection/MarketingPreferencesSection'
import { initialValuesMarketingPreference, marketingPreferenceValidationSchema } from 'pages/MyAccountPage/validationSchema'
import moment from 'moment'

export interface Props {
  content: ContentType[]
  current: SelectedPolicy
  renewed?: SelectedPolicy
  policyId: LabelValuePair
  autoRenew: boolean
  currentPolicyIndex: number
  marketingPreference: string
  getAccountInformationAction: VoidFunction
  getMyPolicyPageContentAction: () => void
  showMTAModalAction: (action: any) => void
  editMarketingPreferences: (action: any) => void
  updateRenewalPreferenceAction: (action: any) => void
  policySelectAction: (action: PolicySelectRequest) => void
}

const MyPolicyPage = ({
  content,
  current,
  renewed,
  policyId,
  autoRenew,
  marketingPreference,
  getAccountInformationAction,
  getMyPolicyPageContentAction,
  showMTAModalAction,
  updateRenewalPreferenceAction,
  policySelectAction,
  editMarketingPreferences,
  currentPolicyIndex,
}: Props) => {
  const [policyDriversContent, setPolicyDriversContent] = React.useState<ContentType | undefined>(undefined)
  const [marketingPreferencePopup, setMarketingPreferencePopup] = React.useState(false)
  const [marketingPreferenceOptOutPopup, setMarketingPreferenceOptOutPopup] = React.useState(false)

  const [helpSection, setHelpSection] = React.useState<ContentType | undefined>(undefined)
  const [initialPopupLoader, setInitialPopupLoader] = React.useState(false)
  const currentPolicy = portalTools(current)
  const currentPolicyValues = currentPolicy ? currentPolicy.getSectionValues().myCover() : null
  const renewalCoverStartDate = currentPolicyValues ? moment(currentPolicyValues.expiration, 'DD/MM/YYYY') : null;

  const policyHolderDetails = currentPolicy.policyHolderInfo().getValues('displayName', 'cellNumber', 'emailAddress1')
  const formRef = React.useRef<FormikProps<any>>(null)

  const onSubmitAlert = () => {
    setMarketingPreferencePopup(false)
    setMarketingPreferenceOptOutPopup(true)
  }

  const handleMarketingPreferenceChange = (values: any, formikHelpers: FormikHelpers<any>) => {
    if(values.marketingPreference == 'Yes, I Agree'){
      editMarketingPreferences({
        marketingPrefEmail: true,
        marketingPrefPost: true,
        marketingPrefMobileApp: true,
        marketingPrefLandline: true,
        marketingPrefVoiceMob: true,
        marketingPrefSMS: true,
        marketingOptOut: false
      })
  } else {
      editMarketingPreferences({
        marketingPrefEmail: false,
        marketingPrefPost: false,
        marketingPrefMobileApp: false,
        marketingPrefLandline: false,
        marketingPrefVoiceMob: false,
        marketingPrefSMS: false,
        marketingOptOut: true
    })
  }
  history.push(EndPoint.MY_ACCOUNT)
}
  const now = moment()
  const showNewPCPDdoc = moment('20231229', 'YYYYMMDD')
  const PCPD = 'https://assets.ctfassets.net/numinvtctmda/6qBToLDi5L0MPUtX40DaDG/a3703562a36caa7bbbe86f21a1a476bb/TYA_PCPD_v4.0.pdf'
  const showPCPDdoc = now.isAfter(showNewPCPDdoc)
  const isRenewalWithin30Days = now.isBetween(moment(renewalCoverStartDate).subtract(30, 'days'), moment(renewalCoverStartDate))
  React.useEffect(() => {
    content.forEach(item => {
      if (item.id === 'policyHelpSection' && item.helpSection) {
        item.helpSection.forEach(section => {
          if (section.id === 'helpSectionComplaints') {
            section.details.forEach(detail => {
              if (detail.type === 'text-icon') {
                detail.body = `We aim to provide a high level of service but if we don’t meet your expectations, please contact us: \n\n *0333 103 7791* \n\n Email: *[complaints.car@toyotainsuranceservices.co.uk](mailto:complaints.car@toyotainsuranceservices.co.uk)* \n\n __Post: Complaints Officer, Toyota Insurance Services, PO Box 1308, Newcastle upon Tyne, NE12 2BF__ \n\n For more information on our complaints procedure, ${
                  !showPCPDdoc ? `please see our [Private Car Policy Document.](${PCPD})` : `please see your Private Car Policy Document which can be found within 'My Documents' once logged into your portal.`
                }`
              }
            })
          }
        })
      }
    })
    if (isEmpty(content) === true) {
      getMyPolicyPageContentAction()
    }
    if ( marketingPreference !== undefined && marketingPreference !== '') {
      formRef?.current?.setFieldValue('marketingPreference', 'Yes, I Agree')
    } else {
      getAccountInformationAction()
    }
    let timerTimeout: any;
    // if(marketingPreference == undefined || marketingPreference == ''){
    //   formRef?.current?.setFieldValue('marketingPreference', 'Yes, I Agree')
    //   setMarketingPreferencePopup(true);
    //   timerTimeout = setTimeout(() => {
    //     setInitialPopupLoader(true)
    //   }, 3000)
    // }

    if (isEmpty(content) === false) {
      setPolicyDriversContent(content.find(item => item.id === 'policyDrivers'))

      setHelpSection(content.find(item => item.id === 'policyHelpSection'))
    }
    return () => clearTimeout(timerTimeout);
  }, [content, current, marketingPreference])

  return (
    <>
      {marketingPreferencePopup && initialPopupLoader && (
        <AlertMessage alertType="mandate" isClosePopup={!marketingPreferencePopup}>
          <AlertMessageInfo
            title="Action Required"
            description="In order to proceed, your marketing preferences need to be updated."
            submitButtonText="Continue"
            submitAction={onSubmitAlert}
          />
        </AlertMessage>
      )}
      {marketingPreferenceOptOutPopup && (
        <AlertMessage alertType="mandate" isClosePopup={!marketingPreferenceOptOutPopup}>
            <>
              <div
                style={{ display: 'flex', justifyContent: 'center', fontSize: 24, paddingTop: '2rem' }}
              >
                Please update your marketing preferences
              </div>
              <Formik
                innerRef={formRef}
                initialValues={{marketingPreference: 'Yes, I Agree'}}
                validationSchema={marketingPreferenceValidationSchema()}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values: any, formikHelpers: FormikHelpers<any>) => {
                  handleMarketingPreferenceChange(values, formikHelpers)
                  formikHelpers.setSubmitting(false)
                }}
              >
                {(formikPropsMarketingPreference: FormikProps<any>) => (
                  <Grid item xs={12}>
                    <Section title="My marketing preferences">
                      <Box pt={2.5} pr={2.5} pl={2.5} pb={2.5}>
                        <MarketingPreferencesSection
                          onSaveChanges={() => {
                            formikPropsMarketingPreference.submitForm()
                          }}                        />
                      </Box>
                    </Section>
                  </Grid>
                )}
              </Formik>
            </>
        </AlertMessage>
      )}
      {isEmpty(content) === false && (
        <Layout>
          <Box pt={8}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Section>
                  <MyCoverDetails
                    myCoverValues={currentPolicy.getSectionValues().myCover()}
                    myCarDetailsValues={currentPolicy.getSectionValues().myCarDetails()}
                    editCar={() => showMTAModalAction({ redirect: EndPoint.EDIT_CAR })}
                  />
                </Section>
              </Grid>

              <Grid item xs={12}>
                <Section title='My excess' accordian childrenPadding={false}>
                  <ExcessTable myExcessPayment={currentPolicy.getMyExcessPayments().display()} />
                </Section>
              </Grid>

              <Grid item xs={12}>
                <Section title='Policy holder' accordian>
                  <PolicyHolderTable
                    policyHolderDetails={policyHolderDetails}
                    headers={[
                      { label: 'Driver', value: 'displayName' },
                      { label: 'Contact number', value: 'cellNumber' },
                      { label: 'Email address', value: 'emailAddress1' },
                    ]}
                  />
                </Section>
              </Grid>

              <Grid item xs={12}>
                <Section title='Drivers' accordian>
                  <MyDrivers
                    content={policyDriversContent as ContentType}
                    mainDriver={currentPolicy.allDrivers().mainDriver('display') as DriverDisplay}
                    additionalDrivers={
                      currentPolicy.allDrivers().total() > 1
                        ? (currentPolicy.allDrivers().additionalDrivers('display') as DriverDisplay[])
                        : undefined
                    }
                    addDriver={() => showMTAModalAction({ redirect: EndPoint.ADD_DRIVER })}
                  />
                </Section>
              </Grid>

              <Grid item xs={12}>
                <Section title='Overnight parking' accordian>
                  <YourAddress
                    address={currentPolicy.overnightParking().details()}
                    editAddress={() => showMTAModalAction({ redirect: EndPoint.EDIT_OVERNIGHT_ADDRESS })}
                  />
                </Section>
              </Grid>

              <Grid item xs={12}>
                <Section title='My Renewal Preferences' accordian>
                  <MyRenewalPreferences
                    policyId={policyId.value}
                    autoRenew={autoRenew}
                    updateRenewalPreferenceAction={updateRenewalPreferenceAction}
                    refreshPolicy={() => policySelectAction({ index: currentPolicyIndex, policyId: policyId.value })}
                    isRenewalWithin30Days={isRenewalWithin30Days}
                  />
                </Section>
              </Grid>

              {helpSection && (
                <Grid item xs={12}>
                  <Section title={helpSection.title || ''}>
                    <HelpSection showTitle={false} content={helpSection} />
                  </Section>
                </Grid>
              )}
            </Grid>
          </Box>
        </Layout>
      )}
    </>
  )
}

export default MyPolicyPage
