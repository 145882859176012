import { Box, FormControl, RadioGroup, FormControlLabel, Radio, makeStyles } from '@material-ui/core'

import ReactMarkdown from 'react-markdown'

const useStyles = makeStyles(theme => ({
  field: {
    padding: '8px 20px 8px 4px',
    height: '50px',
    width: 'fit-content',
    borderRadius: '4px',
    marginLeft: 0,
  },
  fieldBorder: {
    border: 'solid 1px #d8d8d8',
  },
  fieldBorderError: {
    border: `solid 1px ${theme.palette.error.main}`,
  },
}))

export interface MyRenewalPreferencesProps {
  policyId: string
  autoRenew: boolean
  updateRenewalPreferenceAction: (action: any) => void
  refreshPolicy: () => void
  isRenewalWithin30Days: boolean
}

const MyRenewalPreferences = ({
  policyId,
  autoRenew,
  updateRenewalPreferenceAction,
  refreshPolicy,
  isRenewalWithin30Days,
}: MyRenewalPreferencesProps) => {
  const classes = useStyles()

  const handleOnClick = (option: string) => {
    updateRenewalPreferenceAction({
      policyId,
      value: option === 'optIn' ? true : false,
      refreshPolicy: () => refreshPolicy(),
    })
  }

  return (
    <div>
      <ReactMarkdown>{`If you have opted for automatic renewal, we will renew your policy using the payment details you have previously given, provided your card/instalment details allow this. If you have set up an instalment plan, any automatic renewal will be onto this instalment plan instead of charging your card. \n\n We will only automatically renew your policy after issuing you with a renewal notice approximately 21 days before the end of your policy. If you do not want us to automatically renew your cover, or you would like to opt in to automatic renewal, please be aware you must do this more than 30 days before your renewal date. Any change made within 3 days of your policy renewal date will not be applied to the upcoming renewal. \n\n If you are not the card or account holder you must ensure that the card/account holder has agreed to make the renewal payment and you must tell them of any changes to the amount. Your renewal payment will be taken from your account 3 days before the renewal date. \n\n If you have opted for manual renewal and want to accept our renewal offer, you'll need to do this in Your Portal or call us on 0333 103 7791 before your policy expires. If you don't have a motor insurance policy in force and you use your car on public roads, you run the risk of prosecution.`}</ReactMarkdown>

      <Box pt={4}>
        <FormControl error={false}>
          <RadioGroup
            row
            value={autoRenew === true ? 'optIn' : 'optOut'}
            onChange={(event: any) => handleOnClick(event.target.value)}
          >
            <FormControlLabel
              className={`${classes.field} ${classes.fieldBorder}`}
              value="optIn"
              control={<Radio />}
              label="Opt in"
              disabled={isRenewalWithin30Days}
            />
            <FormControlLabel
              className={`${classes.field} ${classes.fieldBorder}`}
              value="optOut"
              control={<Radio />}
              label="Opt out"
              disabled={isRenewalWithin30Days}
            />
          </RadioGroup>
          {/* TODO: Needs an error in case of failed api call. none specifed at this time */}
          {/* {error && <FormHelperText>{'Error text'}</FormHelperText>} */}
        </FormControl>
      </Box>
    </div>
  )
}

export default MyRenewalPreferences
