import { Typography } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'

const HoDoWeUseYourInfo = () => {
  return (
    <Typography component="div">
      <ReactMarkdown linkTarget="_blank">
        {`### How do we use your information? \n\n We use your information to assess your insurance application and our terms of cover, and for this we may share your information with trusted partners and suppliers.

Please read our [Privacy Notice](https://assets.ctfassets.net/numinvtctmda/3YWJlYGzgrkNUTUIa74UYw/06f4a1c661a4e6309781e0c83d147bce/TYA_Privacy_Notice_FINAL_v1.0.pdf) and [Cookie Policy](https://assets.ctfassets.net/numinvtctmda/69bPyHFR1FkcqDlZMVWV5r/6a3ebfe13332abd29708efccdfd15c7c/TYA_Cookie_Policy_v1.0.pdf) to find more about how we use your information.`}
      </ReactMarkdown>
    </Typography>
  )
}

export default HoDoWeUseYourInfo
