import React from 'react'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core'

import CustomerName from 'components/renewal/CustomerName'
import YourPremiumSection from 'components/renewal/YourPremiumSection'
import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import Title from 'components/ui/Title'
import AddonAccordian from 'components/ui/AddonAccordian'
import ExcessTable from 'components/ui/ExcessTable'

import { BillingId } from 'guidewire/policy/enums/billingId'
import { Display } from 'guidewire/policy/enums/display'
import { renewalQuote } from 'guidewire/policy/quote/renewalQuote'
import { costObjectToString } from 'guidewire/format/costObjectToString'

import { RenewalQuote } from 'guidewire/policy/types/renewalQuote'

import { LabelValuePair } from 'types'

import addonSummaries from 'mock/cms/quoteAddonSummary.json'
import HowDoWeUseYourInfo from 'components/ui/HowDoWeUseYourInfo'
import { history } from 'utils'

export interface Props {
  quote: RenewalQuote
  amend: boolean
  policyId: LabelValuePair
  index: number
  continueToPaymentAction: (value: any) => any
  setPaymentPlanAction: ({ setPaymentPlan }: any) => void
  setVoluntaryExcessAction: (value: any) => void
  policySelectAction: ({ index, policyId }: { index: number; policyId: string }) => void
}

const useStyles = makeStyles(theme => ({
  paymentSelect: {
    border: 'solid 1px #d8d8d8',
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
    margin: 0,
    '& .MuiTypography-root': {
      width: '100%',
    },
  },
  paymentSelectLabel: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paymentSelectActive: {
    backgroundColor: '#eff0f0',
  },
  amendDetailsBox: {
    border: '2px solid #ff0022',
    padding: 16,
    marginBottom: 16,
    textAlign: 'center',
    fontWeight: 600,
    color: '#ff0022',

  },
}))

const TestPage = ({
  quote,
  amend,
  policyId,
  index,
  continueToPaymentAction,
  setPaymentPlanAction,
  setVoluntaryExcessAction,
  policySelectAction,
}: Props) => {
  const classes = useStyles()
  const defactoDate = moment('20230601')
  const gw = renewalQuote(quote)

  const customerName = gw.getAccountHolder.details().firstName
  const billingId = gw.getBindData.getSelectedBillingId()
  const { annual, installment } = gw.getBindData.getPlanCosts() as { annual: string; installment: string }
  const representativeExample = gw.getBindData.getRepresentativeExample()
  const transactionFee = gw.getBindData.getSelectedPaymentPlan(Display.DISPLAY).fees_itb

  const addOns = gw.getIncludedAddons.getQuoteAddonSummary()

  const voluntaryExcessOptions = gw.getVehicle.getVoluntaryExcessOptions('asLabelValuePair') as LabelValuePair[]
  const voluntaryExcessValue = gw.getVehicle.getVoluntaryExcess().toString()
  const myExcessPayment = gw.getMyExcessPayment.getMyExcessDriversTable()

  const [refreshPolicies, setRefreshPolicies] = React.useState(false)
  const ncdYears: string = gw.getVehicle.getNcdYears('withYears') as string
  const ncdProtection = gw.getVehicle.getNcdProtection()
  const ncdCosts = gw.getVehicle.getNcdCosts()
  const showNcdProtection = gw.getVehicle.getNcdProtectionAllowed()

  const onVoluntaryExcessSelect = (value: string) => {
    setVoluntaryExcessAction({
      requestObject: {
        sessionUUID: quote.sessionUUID,
        quoteID: quote.quoteID,
        quote: quote.quoteData.offeredQuotes[0],
        coverages: [],
        voluntaryExcess: parseInt(value),
        ncdProtection: false,
      },
      quoteId: policyId.value,
    })
    setRefreshPolicies(true)
  }

  React.useEffect(() => {
    if (refreshPolicies === true) {
      policySelectAction({ index, policyId: policyId.value })
      setRefreshPolicies(false)
    }
  }, [quote, amend])

  // this replaces the selected payment plan in the redux quote state with either the annual or monthly option
  // it does not make a call to gw (there is no need at this point - test)
  const onPaymentSelect = (plan: string) => {
    const paymentPlan = gw.getBindData.getPaymentPlans().find(item => item.billingId === plan)
    setPaymentPlanAction({ selectedPaymentPlan: paymentPlan })
  }
  const effectiveDate = moment(quote.baseData.periodStartDate)

  const showDefactoPNCDLink = effectiveDate.isSameOrAfter(defactoDate)

  return (
    <>
      <Layout background="white">
        <Section>
          <CustomerName content={{ title: 'Here is your renewal offer' }} customerName={customerName} />

          <YourPremiumSection
            content={{
              title: '',
              subtitle:
                billingId === BillingId.ANNUAL
                  ? `Based on your information, your annual premium is ${annual}`
                  : `Based on your information, your monthly payment is ${installment}`,
              body: `Please check that the information we hold about you is accurate, complete and has not changed. Not informing us about inaccurate details may affect any claim you make or could result in your insurance being invalid. \n\n This quote includes Insurance Premium Tax and is valid until your policy expiry date, unless you change any details.`,
              iconImage:
                'https://images.ctfassets.net/numinvtctmda/6QGYK4FXkPavW4Rs0t9pAw/31b13bc70ed2e0d8a67adb49430b197b/Toyota_Yaris.png',
            }}
            annualPremium={''}
          />

          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Title title="Your payment options" />
              <Typography component="div">
                <ReactMarkdown>
                  {`You can pay for your policy as an annual payment today or by setting up a monthly Direct Debit plan. \n\n Paying for your policy by monthly direct debit is more expensive than making a one off annual payment. We've included a  table below to show you how this will work and what the difference in cost will be.`}
                </ReactMarkdown>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <RadioGroup
                name="billingId"
                onChange={event => {
                  const { value } = event.target
                  onPaymentSelect(value)
                }}
                value={billingId}
              >
                <FormControlLabel
                  className={`${classes.paymentSelect} ${
                    billingId === BillingId.ANNUAL ? classes.paymentSelectActive : ''
                  }`}
                  value={BillingId.ANNUAL}
                  control={<Radio color="secondary" />}
                  label={
                    <div className={classes.paymentSelectLabel}>
                      <div>Annual payment</div>
                      <div>
                        <b>{annual}</b>
                      </div>
                    </div>
                  }
                />
                <br />
                <FormControlLabel
                  className={`${classes.paymentSelect} ${
                    billingId === BillingId.INSTALLMENT ? classes.paymentSelectActive : ''
                  }`}
                  value={BillingId.INSTALLMENT}
                  control={<Radio color="secondary" />}
                  label={
                    <div className={classes.paymentSelectLabel}>
                      <div>
                        Monthly payments <br />
                        <b>11 monthly payments</b>
                      </div>
                      <div>
                        <b>{installment}</b>
                      </div>
                    </div>
                  }
                />
              </RadioGroup>
              {billingId === 'bc:7' && (
                <>
                  <div style={{ border: '1px solid #d8d8d8', marginTop: 16 }}>
                    <div style={{ padding: 16, fontWeight: 600, borderBottom: '1px solid #d8d8d8' }}>
                      Representative Example
                    </div>
                    {(representativeExample as LabelValuePair[]).map((item, index) => (
                      <div
                        key={item.label}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: 16,
                          borderBottom:
                            index !== (representativeExample as LabelValuePair[]).length - 1 ? '1px solid #d8d8d8' : 0,
                        }}
                      >
                        <div>{item.label}</div>
                        <div>{item.value}</div>
                      </div>
                    ))}
                  </div>

                  <Typography component="div">
                    <ReactMarkdown>{`- The total cost to you for paying by monthly direct debits is the Transaction fee ${transactionFee}
 - Security over your policy is required when choosing the monthly payment option. 
 - Whilst there is no interest on the loan, the Transaction fee is taken into account in calculating the APR.
 - We act as a credit broker, and are not a lender.
 `}</ReactMarkdown>
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Title title="Your excess" />

              {amend && (
                <div>
                  <Typography style={{ marginBottom: '10px' }}>Voluntary excess</Typography>
                  <FormControl variant="outlined" style={{ width: '40%', marginBottom: '16px' }}>
                    <Select
                      value={voluntaryExcessValue}
                      onChange={event => onVoluntaryExcessSelect(event.target.value as string)}
                    >
                      {voluntaryExcessOptions.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}

              <Typography style={{ marginBottom: 32 }}>
                {'Excess is the amount you will need to pay in the event of a claim for the damage to your car'}
              </Typography>

              <ExcessTable myExcessPayment={myExcessPayment} />
            </Grid>

            <Grid item xs={12}>
              <Title title="Your cover" />
              <Typography>
                {`Please check the details below to make sure the cover you've selected meets your needs.`}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <div style={{ borderTop: '1px solid #eff0f0', marginBottom: 16 }}>
                {[
                  { label: 'Policy number', value: policyId.label },
                  { label: 'Cover type', value: 'Comprehensive' },
                  ...(showNcdProtection ? [{
                    label: 'No Claim Discount',
                    value: showDefactoPNCDLink
                      ? `${gw.getVehicle.getNcdYears('withYears') as string} \n\n Please read the [PNCD Appendix](https://assets.ctfassets.net/numinvtctmda/s4jgR7G6bQmdyQ7tm6kWf/417bed2634e9cf64800751b96e3b3154/TYA_PNCD_Appendix_2024.pdf) to understand the main benefits and restrictions`
                      : `${gw.getVehicle.getNcdYears('withYears') as string} \n\n Please read the [PNCD Appendix](https://assets.ctfassets.net/numinvtctmda/3tN6F0T9iTWKVQUjlwWgHP/81846390e948498e04acd8cf69a3e291/TYA_PNCD_Appendix.2023.pdf) to understand the main benefits and restrictions`,
                  }] : []),
                  { label: 'Other drivers', value: `${gw.getDrivers.getTotalOtherDrivers()}` },
                  { label: 'Excess', value: gw.getMyExcessPayment.getTotalExcess('withCurrency') as string },
                  { label: 'Windscreen replacement', value: gw.getMyExcessPayment.getGlassReplacementExcess() },
                  { label: 'Windscreen repair', value: gw.getMyExcessPayment.getGlassRepairExcess() },
                ].map(item => (
                  <div
                    key={item.label}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '0 16px',
                      borderBottom: '1px solid #eff0f0',
                      alignItems: 'center',
                      gap: 20,
                    }}
                  >
                    <div style={{ flexBasis: '40%' }}>{item.label}</div>
                    <div style={{ flexBasis: '60%', textAlign: 'right' }}>
                      <ReactMarkdown linkTarget="__blank">{item.value}</ReactMarkdown>
                    </div>
                  </div>
                ))}
              </div>
            </Grid>

            <Grid item xs={12}>
            <Box className={classes.amendDetailsBox}>
            If you would like to change your optional add-ons, please return to My renewal and select Amend details. If you are happy with your current coverage, please Continue your renewal below. 
            </Box>
              <Box pt={4} pb={4}>
                  {
                  showNcdProtection && (
                    <AddonAccordian
                    label={'No Claim Discount Protection'}
                    selected={ncdProtection}
                    cost={costObjectToString({ amount: ncdCosts.amount, currency: 'gbp' })}
                    costMonthly={costObjectToString({ amount: ncdCosts.amount / 11, currency: 'gbp' })}
                    ncdYears={ncdYears}
                    content={
                      addonSummaries.find(summary => summary.id === 'NoClaimDiscountProtection' ) as {
                        id: string
                        body: string
                        summary: string
                        monthly: string
                        annually: string
                      }
                    }
                  />
                  )
                }
                {addOns.map(item => (
                  <div key={item.codeIdentifier}>
                    <AddonAccordian
                      label={item.name}
                      selected={item.selected}
                      cost={costObjectToString(item.amount)}
                      costMonthly={costObjectToString({ amount: item.amount.amount / 11, currency: 'gbp' })}
                      ncdYears={''}
                      content={
                        addonSummaries.find(summary => summary.id === item.codeIdentifier) as {
                          body: string
                          id: string
                          summary: string
                          monthly: string
                          annually: string
                        }
                      }
                    />
                  </div>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Section>
      </Layout>

      <Layout background="#eff0f0">
        <Box pt={8} pr={2.5} pl={2.5} pb={4}>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={12}>
              <HowDoWeUseYourInfo />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Divider />
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  policySelectAction({ index, policyId: policyId.value })
                  history.push('/my-renewal')
                }}
              >
                Save quote
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => continueToPaymentAction({ billingId })}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Layout>
    </>
  )
}

export default TestPage
