import { connect } from 'react-redux'
import { getMyPolicyPageContentAction, policySelectAction } from 'redux/policy/actions'
import { showMTAModalAction } from 'redux/mta/actions'

import { IReduxState } from 'types/stateTypes'
import MyPolicyPage from './MyPolicyPage'
import { updateRenewalPreferenceAction, getAccountInformationAction, updateMarketingPreferencesAction } from 'redux/account/actions'

const mapStateToProps = (state: IReduxState) => ({
  content: state.policy.content,
  current: state.policy.current,
  renewed: state.policy.renewed,
  policyId: state.policy.policyIds[state.policy.currentPolicyIndex],
  autoRenew: state.policy.autoRenew,
  currentPolicyIndex: state.policy.currentPolicyIndex,
  marketingPreference: state.account.marketingPreference,
})

const mapDispatchToProps = {
  getMyPolicyPageContentAction: getMyPolicyPageContentAction.start,
  showMTAModalAction: showMTAModalAction.start,
  updateRenewalPreferenceAction: updateRenewalPreferenceAction.start,
  policySelectAction: policySelectAction.start,
  getAccountInformationAction: getAccountInformationAction.start,
  editMarketingPreferences: updateMarketingPreferencesAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPolicyPage)
