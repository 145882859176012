import { Slide, Typography } from '@material-ui/core'
import { FormikProps } from 'formik'

import FieldUI from 'components/formUI/FieldUI'
import Title from 'components/ui/Title'

import { PaymentPlanDisplay } from 'guidewire/policy/types/paymentPlan'

import { LabelValuePair } from 'types'

export interface InstallmentAgreementProps {
  selectedPaymentPlan: PaymentPlanDisplay
  checkboxProps: { name?: string; formik?: FormikProps<any>; label?: string }
}

const InstallmentAgreement = ({ selectedPaymentPlan, checkboxProps }: InstallmentAgreementProps) => {
  const installments = [
    { label: 'Annual Premium', value: 'totalPremiumRPT' },
    { label: 'Amount of Credit', value: 'creditAmount_itb' },
    { label: 'Interest rate  fixed', value: 'interestRate_itb' },
    { label: 'Transaction Fee', value: 'fees_itb' },
    { label: `${selectedPaymentPlan.numberOfInstallments} monthly payments of`, value: 'installment' },
    { label: 'Total amount payable', value: 'total' },
    { label: 'APR', value: 'aprPercentage_itb' },
  ] as LabelValuePair[]

  return (
    <>
      <Title
        title="You have chosen to pay by monthly instalments"
        text={`As you've chosen to pay monthly, here's how this will work:`}
      />

      <div style={{ border: '1px solid #d8d8d8', backgroundColor: '#eff0f0' }}>
        {installments.map((item, index) => (
          <div
            key={item.label}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px 16px',
              borderBottom: index !== installments.length - 1 ? '1px solid #d8d8d8' : 0,
            }}
          >
            <div>{item.label}</div>
            <div>{selectedPaymentPlan[item.value]}</div>
          </div>
        ))}
      </div>

      <br />

      <Typography>
        {`Whilst there is no interest on the loan, the transaction fee is taken into account when calculating the APR`}
      </Typography>

      <br />

      <FieldUI {...checkboxProps} type="checkbox-agreement" />
    </>
  )
}

export default InstallmentAgreement
